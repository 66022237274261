<template>
  <div class="w-full">
    <div>
      <TotalComplaints :total="count" title="Total de Quejas" />
      <ComplaintsFilter
        @fetch-complaints="fetchProductivity"
        :loading="loading"
        :query="start_query"
      />
    </div>
    <div class="py-12" v-if="legend">
      <b class="text-center text-4xl mx-auto text-gray-400">{{ legend }}</b>
    </div>
    <div
      class="overflow-x-auto rounded-xl border border-gray-300 mx-4 mt-7 history"
      v-if="data.length > 0"
    >
      <a-table
        class="bg-white m"
        :columns="columns"
        rowKey="codigo_queja"
        :data-source="data"
        :pagination="false"
      >
        <div slot="actions" slot-scope="text, record">
          <button
            class="flex justify-center col-span-1 text-xs pr-2 text-blue h-min"
            @click="complaintDetails(text, record)"
          >
            <a-icon
              type="eye"
              theme="twoTone"
              two-tone-color="#3366cc"
              :style="{
                fontSize: '20px',
              }"
            />
            <b class="my-auto ml-2">Ver</b>
          </button>
        </div>
      </a-table>

      <div class="p-4 flex justify-end">
        <a-pagination
          v-model="page"
          :page-size="pageSize"
          :page-size-options="pageSizeOptions"
          :total="count"
          show-size-changer
          size="large"
          @showSizeChange="onShowSizeChange"
        />
      </div>
    </div>
    <div v-if="loading" class="absolute w-full justify-center mt-7">
      <a-spin>
        <a-icon slot="indicator" type="loading" style="font-size: 64px" spin />
      </a-spin>
    </div>
  </div>
</template>
<script>
import ModalConfirmation from "../Companies/ModalConfirmation.vue";
import ComplaintStatusLabel from "../Complaints/ComplaintStatusLabel";
import TotalComplaints from "@/components/Dashboard/Complaints/TotalComplaints.vue";
import ComplaintsFilter from "@/components/Dashboard/Complaints/ComplaintsFilter";
import { format } from "date-fns";
import capitalize from "@/utils/checkForms.js";
import moment from "moment";

const columns = [
  {
    title: "Id. de radicado",
    dataIndex: "codigo_queja",
    ley: "codigo_queja",
  },
  {
    title: "Nombre del responsable",
    dataIndex: "nombres",
    key: "nombres",
  },
  {
    title: "Entidad",
    dataIndex: "company_name",
    key: "company_name",
  },
  {
    title: "Fecha de creación",
    dataIndex: "fecha_creacion",
    key: "fecha_creacion",
  },
  {
    title: "Estado actual",
    dataIndex: "estado_cod__name",
    key: "estado_cod__name",
  },
  {
    title: "Queja principal",
    dataIndex: "complaint_type",
    key: "complaint_type",
  },
  {
    title: "Histórico",
    dataIndex: "detail",
    key: "detail",
    scopedSlots: { customRender: "actions" },
  },
];

let data = [];

export default {
  components: {
    ModalConfirmation,
    ComplaintsFilter,
    TotalComplaints,
  },
  data() {
    return {
      data,
      columns,
      loading: false,
      page: 1,
      pageSize: 100,
      count: 0,
      legend: "",
      showModal: false,
      formQuery: {},
      start_query: {},
      pageSizeOptions: ["50", "100", "150", "200", "250"],
    };
  },
  created() {
    this.undoneQuery();
    if (!this.formQuery.start_date || this.formQuery.start_date == "") {
      const start_date = moment()
        .set(new Date())
        .subtract(7, "d")
        .format("YYYY-MM-DD");
      const end_date = moment().set(new Date()).format("YYYY-MM-DD");

      this.formQuery.start_date = start_date;
      this.formQuery.end_date = end_date;
    }

    if (this.$route.query.page) {
      this.page = parseInt(this.$route.query.page);
    } else {
      this.page = 1;
    }
    if (this.$route.query.pageSize) {
      this.pageSize = parseInt(this.$route.query.pageSize);
    } else {
      this.pageSize = 100;
    }
    this.start();
  },
  computed: {},
  watch: {
    page: function (newVal, oldVal) {
      if (newVal == this.$route.query.page) {
        return;
      } else {
        this.updateRouterQuery();
      }
    },
    pageSize: function (newVal, oldVal) {
      if (newVal == this.$route.query.pageSize) {
        return;
      } else {
        this.$router
          .replace({
            query: { page: this.page, pageSize: this.pageSize },
          })
          .catch(() => {
            return;
          });
      }
    },
    "$route.query.page": function () {
      this.start();
    },
  },
  methods: {
    start() {
      this.fetchProductivityQuery();
    },
    async setFilterInData(query) {
      this.loading = true;
      let { error, data } = await this.$api.getComplaintsAll(query);
      this.loading = false;

      if (error) this.legend = "Ocurrió un error con la consulta";

      if (data) {
        //Get total complaints
        this.loading = true;
        let res_count = await this.$api.getComplaintsAll(query + `&get_count=yes`);
        this.loading = false;
        let total = 0;
        
        if (res_count.data){
          total = res_count.data.count;
        }
          
        this.count = total;
        if (this.count != 0) {
          this.data = data.results.map((complaint) => {
            if (complaint.codigo_queja_principal)
              complaint.estado_duplicidad__name = complaint.codigo_queja_principal =
                "Unificada en " + complaint.codigo_queja_principal;
            else if (complaint.estado_duplicidad__name === "Duplicada")
              complaint.estado_duplicidad__name = "Duplicada";
            else complaint.estado_duplicidad__name = "Es queja principal";
            return {
              ...complaint,
              nombres: capitalize(complaint.nombres),
              company_name: capitalize(complaint.company_name),
              fecha_creacion: format(
                new Date(complaint.fecha_creacion),
                "dd/MM/yyyy hh:mm a"
              ),
              estado_cod__name: (
                <ComplaintStatusLabel
                  left={true}
                  status={complaint.estado_cod__name}
                  danger="true"
                />
              ),
              complaint_type: (
                <ComplaintStatusLabel
                  left={true}
                  status={complaint.estado_duplicidad__name}
                />
              ),
            };
          });
        } else {
          this.legend = "No se encontraron resultados";
          this.data = [];
        }
      }
    },
    undoneQuery() {
      if (this.$route.query?.params) {
        let queryArray = this.$route.query?.params.split("&");
        queryArray.forEach((element) => {
          let param = element.split("=");
          this.formQuery[param[0]] = param[1];
        });
        this.start_query = this.formQuery;
      }
    },
    makeQuery() {
      if (this.formQuery) {
        let query_params = Object.keys(this.formQuery)
          .map((key) => {
            if (this.formQuery[key] && this.formQuery[key] !== "") {
              return key + "=" + this.formQuery[key];
            }
          })
          .filter((data) => !!data)
          .join("&");
        return query_params;
      }
      return null;
    },
    fetchProductivity(value) {
      this.legend = "";
      this.formQuery = value;
      this.showToast("info", "Buscando resultados...");
      if (1 == this.$route.query.page || !this.$route.query.page) {
        this.updateRouterQuery();
        this.fetchProductivityQuery();
      }
      this.page = 1;
    },
    fetchProductivityQuery() {
      let query = `?page_size=${this.pageSize}&page=${this.page}`;

      let query_params = this.makeQuery();
      query += query_params ? `&${query_params}` : "";
      this.setFilterInData(query);
    },
    updateRouterQuery() {
      this.$router
        .replace({
          query: {
            page: this.page,
            params: this.makeQuery(),
          },
        })
        .catch((error) => error);
    },
    complaintDetails(text, record) {
      this.$router.push(
        `/dashboard/complaints/historical/${record.codigo_queja}`
      );
    },
    onShowSizeChange(page, page_size) {
      this.page = page;
      this.pageSize = page_size;
      this.fetchProductivityQuery();
    },
    showToast(result = "info", msg) {
      this.$notification[result]({
        message: "Resultado",
        description: msg,
        onClick: () => {},
      });
    },
  },
};
</script>
<style>
.history .ant-table-thead > tr > th {
  font-weight: bold;
  text-align: center;
  color: #6b7280;
}
</style>
