<template>
  <div>
      <HistoryList />
  </div>
</template>

<script>
import HistoryList from '@/components/Dashboard/HistoryComplaint/HistoryList'
export default {
  components: {
    HistoryList
  }
}
</script>